import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import title_image from '../../images/titles/business_strategy.jpg'
import organization_performance_view_360 from '../../images/pages/organization_performance_view_360.png'
import person from '../../images/person.png'
import ContactText from '../../components/ContactText'

const pageTitle = "Business Strategy";
const pageSLug = "business-strategy";
class BusinessStrategy extends React.Component{
  render(){
    return(
      <Layout pageType="business-consulting">
      <SEO title={pageTitle} />
      <section className="section page-title" style={{ backgroundImage: `url(${title_image})`}}>
      <div className="overlay"></div>
        <div className="container">
            <h1 className="title">{pageTitle}</h1>
            <h6 className="subtitle">Together we can help you gain a competitive advantage using business innovation and agility.</h6>
        </div>
      </section>

      <section id="p_business_consulting" className="section static-page page-content">
        <div className="container">
            <div className="columns">
                <div className="column is-12 content-area">
                    <h3>
                    Business innovation and agility are crucial elements for a company in today's world.
                    </h3>
                    <p>Many new disruptive forces are impacting companies in a way not seen before. An influx of new 
                        competitive threats is emerging from a variety of unlikely places. Many companies are unable 
                        to adapt in an agile manner to keep up with innovation. All the while, customer expectations 
                        are evolving at an unprecedented rate.</p>
                    <span className="break"></span>
                    <h6>To adapt, companies must innovate by offering:</h6>
                    <ul>
                        <li>New approaches to service fulfillment and deliver a top-grade customer experience</li>
                        <li>Cutting edge business models that will allow customers to gain more value directly 
                            and with less effort</li>
                        <li>Innovation in products and services</li>
                    </ul>
                <p>Businesses in the market who envision opportunities that their competitors miss while transforming their 
                    organization to make those opportunities a reality can and will gain significant market share. 
                    Unfortunately, if a company is unable to make these strategic decisions, direct relevant communication, 
                    and turn their strategy into action, it will severely under-perform in the marketplace.</p>
                <p>A well thought out strategy that addresses these opportunities is becoming ever more critical. 
                    Our business strategy experts can help you navigate and overcome this journey.</p>
                    <h3 className="intext">Transforming your business begins with assessing performance.</h3>
                    <p>Considering multiple perspectives, including customers, as well as both internal and external players – 
                        industry, competition, technology, and regulatory considerations, is a great way to start.</p>
                    <img src={organization_performance_view_360} />
                    <p>By having a wide-spanning view of organizational performance, you can develop a greater understanding of 
                        your organization's opportunities and challenges. This allows for greater competitive positioning in 
                        regards to broader industry specifications.</p>
                </div>
              {/*  <div className="column is-3 sidebar">
                    <div className="person-info">
                        <img src={person} />
                        <div className="inner">
                            <h2 className="name">Greg Klem</h2>
                            <p className="status">Vice President</p>
                            <p>National Practice Lead - Business </p>
                            <p>Consulting Services</p>
                        </div>
                        <p>Not sure where to start, our team is always ready to help.</p>
                        <Link to="/" className="button green">Talk with a professional</Link>
                    </div>
                </div>*/}
            </div>
        </div>
      </section>

      <section className="section static-page page-services">
          <div className="container">
              <div className="page-title-area">
                  <h2 className="title">Business Strategy Services</h2>
                  <h6 className="subtitle">We can help you grow and transform your business, with:</h6>
              </div>
              <div className="columns is-multiline">
                  <div className="single column is-3">
                      <Link to={"/business-consulting/"+pageSLug+"/value-targeting-and-business-analysis"}  className="inner">
                          <span className="line"></span>
                        <h2 className="title">Value Targeting and Business Analysis</h2>
                        <p className="desc">Together we work with you to validate, identify, 
                        and prioritize value-creation opportunities that achieve holistic advancement and improvement.</p>
                      </Link>
                  </div>
                  <div className="single column is-3">
                      <Link to={"/business-consulting/"+pageSLug+"/customer-journey-mapping-and-experience"} className="inner">
                          <span className="line"></span>
                        <h2 className="title">Customer Journey Mapping and Experience</h2>
                        <p className="desc">We can help you improve the customer experience by visualizing the 
                        customer's journey – which is crucial in differentiating.</p>
                      </Link>
                  </div>
                  <div className="single column is-3">
                      <Link to={"/business-consulting/"+pageSLug+"/vision-and-strategy-management"} className="inner">
                          <span className="line"></span>
                        <h2 className="title">Vision and Strategy Development</h2>
                        <p className="desc">A pragmatic, execution-oriented approach toward strategy 
                        development, guarantees quality results.</p>
                      </Link>
                  </div>
                  <div className="single column is-3">
                      <Link to={"/business-consulting/"+pageSLug+"/business-architecture"} className="inner">
                          <span className="line"></span>
                        <h2 className="title">Business Architecture</h2>
                        <p className="desc">Developing business architecture gives organizations the ability to 
                        have a shared vision and guide their future improvements.</p>
                      </Link>
                  </div>
              </div>
          </div>
      </section>

     <ContactText />

      </Layout>
    )
  }
}

export default BusinessStrategy
